import { Box } from '@mui/material'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import { styled } from '@mui/material/styles'
import theme from 'theme'
const FAQ = styled(Box)`
  /* background-color: #00182a; */
  background: linear-gradient(180deg, #00030c 0%, #00172b 100%);
  margin-top: 40px;
  padding: 40px 160px 100px !important;
  @media (max-width: 1280px) {
    padding: 40px 60px 100px !important;
  }
  h3 {
    margin-bottom: 40px;
    font-size: 36px;
    font-weight: 600;
    color: #ac8a3b;
  }
  > p {
    font-size: 24px;
    color: #fff;
    display: block;
    margin: 20px 0;
    line-height: 30px;
    font-weight: 500;
    > p {
      margin-top: 10px;
      font-size: 16px;
      margin-left: 25px;
      margin-bottom: 20px;
      color: #c7c7c7;
    }
  }
  ${theme.breakpoints.down('md')} {
    padding: 40px 20px 100px !important;
  }
`

const SubTitle = styled('p')`
  font-size: 24px;
  color: #fff;
  display: block;
  margin: 20px 0;
  line-height: 30px;
  font-weight: 500;
`

const Text = styled('p')`
  margin-top: 10px;
  font-size: 16px;
  margin-left: 33px;
  margin-bottom: 20px;
  color: #b5b5b5;
`
const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    background: 'transparent',
    borderBottom: `1px solid #00182a`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  })
)

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '1.3rem', color: '#ac8a3b' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'transparent',
  flexDirection: 'row-reverse',
  padding: 0,
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: '0 10px !important',
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  // borderTop: '1px solid rgba(0, 0, 0, .125)',
  background: 'transparent',
}))

export default function Faq() {
  return (
    <FAQ>
      <h3>FAQ</h3>
      <Accordion>
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <SubTitle>1. Where is the Sanctuary city?</SubTitle>
        </AccordionSummary>
        <AccordionDetails>
          <Text>
            The Sanctuary city is located on the Sandbox, which is one of the largest Metaverse ecosystems on the
            mainnet.
          </Text>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <SubTitle>2. How many Great Z mansions are on sale?</SubTitle>
        </AccordionSummary>
        <AccordionDetails>
          <Text>
            We built 32 Great Z mansions in total and will not reissue them in the future. Twenty-eight of those will be
            listed for sale and four will be kept for Paraland’s future operation.
          </Text>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <SubTitle>3. What does a Great Z mansion include?</SubTitle>
        </AccordionSummary>
        <AccordionDetails>
          <Text>
            Each Great Z mansion includes a 1*1 plot on the Sandbox and a desert-themed villa with eight pieces of
            matching furniture, which are all in NFT forms.
          </Text>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <SubTitle>4. Where can I purchase a Great Z mansion?</SubTitle>
        </AccordionSummary>
        <AccordionDetails>
          <Text>
            Great Z mansions are only available for initial sale on Paraland.xyz, and can be traded on various NFT
            marketplaces in the future.
          </Text>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <SubTitle>5. What can I get after purchasing a Great Z mansion?</SubTitle>
        </AccordionSummary>
        <AccordionDetails>
          <Text>
            You will get a VIP membership NFT that represents your ownership of the villa. Once Sandbox opens its land
            for deployment, you can use this VIP membership NFT to claim your Great Z mansion (including the land, the
            villa, and furnitures) from Paraland.xyz. As a Paraland VIP member, you will have exclusive rights to
            legendary NFTs, enjoy discounts, and receive special gifts.
          </Text>
        </AccordionDetails>
      </Accordion>
      {/* <p>
        
        <p>
          The Sanctuary city is located on the Sandbox, which is one of the largest Metaverse ecosystems on the Ethereum
          mainnet.
        </p>
      </p>
      <p>
        2. How many Great Z mansions are on sale?
        <p>
          We built 32 Great Z mansions in total and will not reissue them in the future. Twenty-eight of those will be
          listed for sale and four will be kept for Paraland’s future operation.
        </p>
      </p>
      <p>
        3. What does a Great Z mansion include?
        <p>
          Each Great Z mansion includes a 1*1 plot on the Sandbox and a desert-themed villa with eight pieces of
          matching furniture, which are all in NFT forms.
        </p>
      </p>
      <p>
        4. Where can I purchase a Great Z mansion?
        <p>
          Great Z mansions are only available for initial sale on Paraland.xyz, and can be traded on various NFT
          marketplaces in the future.
        </p>
      </p>
      <p>
        5. What can I get after purchasing a Great Z mansion?
        <p>
          You will get a VIP membership NFT that represents your ownership of the villa. Once Sandbox opens its land for
          deployment, you can use this VIP membership NFT to claim your Great Z mansion (including the land, the villa,
          and furnitures) from Paraland.xyz. As a Paraland VIP member, you will have exclusive rights to purchase
          legendary NFTs, enjoy discounts, and receive special gifts.
        </p>
      </p> */}
    </FAQ>
  )
}
