import { Grid, Box, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'

import SandBoxPic from 'assets/images/sanctuary/sandbox.png'
import BeamPic from 'assets/images/sanctuary/beam.png'
import OnePic from 'assets/images/sanctuary/1.png'
import A1Pic from 'assets/images/sanctuary/a1.png'
import A2Pic from 'assets/images/sanctuary/a2.png'
import A3Pic from 'assets/images/sanctuary/a3.png'
import A4Pic from 'assets/images/sanctuary/a4.png'

const Article = styled(Box)`
  background: #6e5413;
  padding: 18px 28px 18px 20px;
  display: flex;
  h4 {
    font-size: 32px;
    line-height: 32px;
    font-weight: 500;
    margin-right: 20px;
  }
  p {
    font-size: 20px;
    /* margin-left: 20px; */
    padding-top: 8px;
    font-weight: 500;
  }
`
const LIt = styled(Box)`
  background: #00182a;
  display: flex;
  flex-direction: column;
  width: calc(50% - 4px);
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 10px;
  border-radius: 10px;
  img {
    margin-left: 35px;
  }
  p {
    width: 50px;
  }
  &:nth-child(2n) {
    margin-right: 0;
  }
`

export default function GridArticle() {
  const matches = useMediaQuery((theme: any) => theme.breakpoints.down('md'))
  return !matches ? (
    <>
      <Grid container spacing={{ xs: 0, md: 1, sm: 0 }} columns={{ xs: 3, sm: 3, md: 12, xl: 12 }}>
        <Grid item xs={3.5}>
          <Article>
            <h4>6x6</h4>
            <p>(36parcels)</p>
          </Article>
        </Grid>
        <Grid item xs={5}>
          <Article>
            <h4>32</h4>
            <p>Great Z mansions</p>
          </Article>
        </Grid>
        <Grid item xs={3.5}>
          <Article>
            <h4>4</h4>
            <p>Communal facilities</p>
          </Article>
        </Grid>
      </Grid>
      <Box height={'8px'}></Box>
      <Grid container alignItems={'flex-start'} spacing={1} columns={{ xs: 3, sm: 3, md: 12, xl: 12 }}>
        <Grid item xs={3.5}>
          <Box p={'50px 80px 5px'}>
            <img width={'100%'} src={SandBoxPic} alt="" />
          </Box>
          <Article m={'30px 0 60px'}>
            <p style={{ padding: 0 }}>Design studio</p>
          </Article>
          <Box p={'0 80px'}>
            <img width={'100%'} src={BeamPic} alt="" />
          </Box>
        </Grid>
        <Grid item xs={5}>
          <img width={'100%'} height={'337px'} src={OnePic} alt="" />
        </Grid>
        <Grid item xs={3.5}>
          <Box display={'flex'} flexWrap={'wrap'}>
            <LIt>
              <img width={'97px'} height={'103px'} src={A1Pic} alt="" />
              <p>Office building</p>
            </LIt>
            <LIt>
              <img width={'97px'} height={'103px'} src={A2Pic} alt="" />
              <p>Commercial complex</p>
            </LIt>
            <LIt>
              <img width={'97px'} height={'103px'} src={A3Pic} alt="" />
              <p>Theme park</p>
            </LIt>
            <LIt>
              <img width={'97px'} height={'103px'} src={A4Pic} alt="" />
              <p>&nbsp;</p>
              <p>Stadium</p>
            </LIt>
          </Box>
        </Grid>
      </Grid>
    </>
  ) : (
    <>
      <Grid container spacing={{ xs: 0, md: 1, sm: 0 }} columns={{ xs: 3, sm: 3, md: 12, xl: 12 }}>
        <Grid item xs={3.5}>
          <Article>
            <h4>6x6</h4>
            <p>(36parcels)</p>
          </Article>
          <Box p={matches ? '30px 30px 20px' : '50px 80px 20px'}>
            <img width={'100%'} src={SandBoxPic} alt="" />
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Article>
            <h4>32</h4>
            <p>Great Z mansions</p>
          </Article>
          <Grid item xs={5}>
            <img width={'100%'} height={'337px'} src={OnePic} alt="" />
          </Grid>
        </Grid>
        <Grid item xs={3.5}>
          <Article>
            <h4>4</h4>
            <p>Communal facilities</p>
          </Article>
        </Grid>
        <Grid item xs={3.5}>
          <Box display={'flex'} flexWrap={'wrap'}>
            <LIt>
              <img width={'97px'} height={'103px'} src={A1Pic} alt="" />
              <p>Office building</p>
            </LIt>
            <LIt>
              <img width={'97px'} height={'103px'} src={A2Pic} alt="" />
              <p>Commercial complex</p>
            </LIt>
            <LIt>
              <img width={'97px'} height={'103px'} src={A3Pic} alt="" />
              <p>Theme park</p>
            </LIt>
            <LIt>
              <img width={'97px'} height={'103px'} src={A4Pic} alt="" />
              <p>&nbsp;</p>
              <p>Stadium</p>
            </LIt>
          </Box>
        </Grid>
        <Grid item xs={3.5}>
          <Article m={matches ? '0 0 20px' : '0px 0 60px'}>
            <p style={{ padding: 0 }}>Design studio</p>
          </Article>
          <Box p={matches ? '0 30px' : '0 80px'}>
            <img width={'100%'} src={BeamPic} alt="" />
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
