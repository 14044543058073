import { useState, ImgHTMLAttributes } from 'react'
import loadImg from 'assets/images/svg/puff.svg'

export interface IImagProps<T> extends ImgHTMLAttributes<T> {
  loadingImg?: string
  errorImg?: string
  src: string
  width?: string | number
  height?: string | number
  flag?: boolean
}

export default function Img(props: IImagProps<any>) {
  const [src, setSrc] = useState(props.loadingImg as string)
  const [isFlag, setIsFlag] = useState(props.flag !== undefined ? props.flag : false)
  console.log(isFlag)
  const handleOnLoad = () => {
    if (isFlag) return
    const imgDom = new Image()
    imgDom.src = props.src
    imgDom.onload = function () {
      setIsFlag(true)
      setSrc(props.src)
    }
    imgDom.onerror = function () {
      setIsFlag(true)
      // setSrc(props.errorImg as string)
    }
  }
  return <img width={props.width} height={props.height} src={src} onLoad={handleOnLoad} alt={''}></img>
}

Img.defaultProps = {
  loadingImg: loadImg,
}
