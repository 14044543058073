import { Container, Box, Divider } from '@mui/material'
import { styled } from '@mui/material/styles'
import theme from 'theme'
import BeamPic from 'assets/images/svg/beam.svg'
const Wrapper = styled(Container)`
  width: 100%;
  height: 100%;
  padding-bottom: 30px;
  text-align: center;
  h1 {
    font-size: 40px;
    font-weight: 600;
    line-height: 40px;
    margin-bottom: 20px;
    ${theme.breakpoints.down('md')} {
      font-size: 35px;
      line-height: 40px;
    }
  }
  p {
    font-size: 0.875rem;
    line-height: 24px;
    max-width: 730px;
    margin: 0 auto;
  }
  hr {
    max-width: 730px;
    margin: 30px auto;
  }
  h2 {
    border-radius: 8px;
    background: rgba(175, 164, 255, 0.2);
    display: inline;
    font-size: 24px;
    font-weight: 500;
    color: #afa4ff;
    line-height: 35px;
    padding: 8px 32px;
    margin-bottom: 30px;
  }
  .mt15 {
    margin-top: 30px;
  }
  .mt10 {
    margin-top: 10px;
  }
  .hasb {
    background: linear-gradient(90deg, rgba(122, 110, 191, 0.2) 0%, rgba(175, 164, 255, 0.2) 100%);
    border: 1px solid #afa4ff;
    padding: 10px 0;
    border-radius: 30px 0 30px 0;
    color: #afa4ff;
  }
  div {
    margin: 60px auto 0;
  }
  h3 {
    font-size: 60px;
    /* text-decoration: underline; */
  }
`

export default function About() {
  return (
    <Wrapper maxWidth="xl">
      <h1>About us</h1>
      <p>
        ParaLand is a top-producing luxury real estate company that represents properties of distinction in the
        metaverse. We aim to re-invent the art of real estate.
      </p>
      <p className="mt15">
        Major products include private villas, commercial buildings, fully functional digital cities, and NFT artworks.
        These designs are more than just digital assets, but also pieces of art that embody the essence of architecture,
        aesthetics and cryptography.
      </p>
      <p className="mt15">
        Our goal is to build the ParaLand empire that connects multiple Metaverses, covering business such as investment
        in related projects, development and management of digital assets, as well as construction of Metalife platforms
        and infrastructures.
      </p>
      <Divider className="hr" />
      <h1>Team</h1>
      <h2>Management team</h2>
      <Box mt={'30px'}>
        <h3>Paraland Ltd.</h3>
        {/* <Box maxWidth={'330px'}>
          <h3>Allen</h3>
          <p className="mt10">5+ years crypto asset investment experience</p>
          <p className="mt10">Cofounder of Nothing Research</p>
          <p className="mt10">Former head of staking operations at Huobi</p>
          <p className="mt10">Former analyst of Huobi Research</p>
        </Box>
        <Box maxWidth={'330px'}>
          <h3>Henry</h3>
          <p className="mt10">5+ years crypto market experience</p>
          <p className="mt10">Founder of Snapfinger</p>
          <p className="mt10">Cofounder of Assembly Partner</p>
        </Box> */}
      </Box>
      <Box height={'20px'}></Box>
      <h2 className="mt15">Designer Team</h2>
      <Box height={'0px'}></Box>
      <Box>
        <a href="https://www.beamstudio.pro/" target="_blank" rel="noopener noreferrer">
          <img width={300} src={BeamPic} alt="" />
        </a>
      </Box>
      <br />
      {/* <h3 className="mt15">Dexter</h3>
      <p className="mt10">Senior Visual Designer of a Fortune 500 Company</p>
      <p className="mt10">Participated in the design of Aelia Duty Free, GalariesLafayette, Leon de Bruxelle</p>
      <h3 className="mt15">Finnian</h3>
      <p className="mt10">7+ years of work experience.</p>
      <p className="mt10">Creative director and concept artist in the film, AAA games and TV industries. </p>
      <p className="mt10">
        Participated in the production of Avatar, Thor 4, Star Wars: Rogue One, The Lion Kin, Transformers: The Last
        Knight, Pacific Rim 2, etc.
      </p>
      <h3 className="mt15">Qiu</h3>
      <p className="mt10">Lead Concept Artist on Overwatch @ Blizzard Entertainment</p>
      <h3 className="mt15">Mori</h3>
      <p className="mt10">Art director of a fortune 500 company</p>
      <p className="mt10">
        In charge of the main visual of Coca-Cola 2017 Summer Campaign, Adidas Neo2011, Uniqlo Asia Limited Edition,
        Audi HMI, Converse Chuck Taylor All Star 1970&apos;s, and the limited editions of Kappa, Dunny and many other
        brands.
      </p> */}
    </Wrapper>
  )
}
