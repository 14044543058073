import { useRef } from 'react'
import { Container, Box, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'
import theme from 'theme'

import GCarousel from 'components/GCarousel'
import GridArticle from './GridArticle'
import Faq from './Faq'
import Nft from './Nft'

import TopPic from 'assets/images/png/info-top.png'
import Logo from 'assets/images/sanctuary/sanctuary_logo.png'
import Video from 'components/Video'
import MyVideo from 'assets/media/v.mp4'
import PosterPic from 'assets/media/poster.png'

import Pic1 from 'assets/images/meta/withlogo/withlogo.001.png'
import Pic2 from 'assets/images/meta/withlogo/withlogo.002.jpeg'
import Pic3 from 'assets/images/meta/withlogo/withlogo.003.jpeg'
import Pic4 from 'assets/images/meta/withlogo/withlogo.004.jpeg'
import Pic5 from 'assets/images/meta/withlogo/withlogo.005.jpeg'
import Pic6 from 'assets/images/meta/withlogo/withlogo.006.jpeg'
import Pic7 from 'assets/images/meta/withlogo/withlogo.007.jpeg'
import Pic8 from 'assets/images/meta/withlogo/withlogo.008.png'
import ConePic from 'assets/images/sanctuary/cone.gif'

const carouselData = [
  {
    url: Pic1,
  },
  {
    url: Pic2,
  },
  {
    url: Pic3,
  },
  {
    url: Pic4,
  },
  {
    url: Pic5,
  },
  {
    url: Pic6,
  },
  {
    url: Pic7,
  },
  {
    url: Pic8,
  },
]

const Wrapper = styled(Container)`
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  background: #000;
  padding: 0;
  padding-left: 0 !important;
  padding-right: 0 !important;
  > div,
  > h4,
  > p {
    padding: 0 160px;
    position: relative;
    z-index: 10;
    ${theme.breakpoints.down('md')} {
      padding: 0 20px;
    }
    @media (max-width: 1280px) {
      padding: 0 20px;
    }
  }
`
const TopImg = styled('img')`
  position: absolute;
  top: -70px;
  left: 0;
  width: 100%;
  z-index: 1;
  min-height: 400px;
`
const Desc = styled('p')`
  font-size: 16px;
  margin-top: 15px;
`
const TopText = styled(Box)`
  max-width: 1160px;
  margin: 0 auto;
  display: flex;
  background: #00182a;
  padding: 37px 45px !important;
  margin-top: 40px;
  h3 {
    font-size: 36px;
    font-weight: 500;
    width: 510px;
    line-height: 45px;
  }
  p {
    font-size: 16px;
    flex: 1;
    line-height: 34px;
  }
  ${theme.breakpoints.down('md')} {
    display: block;
    padding: 37px 20px !important;
    h3 {
      width: 100%;
      margin-bottom: 20px;
    }
  }
`
const Text = styled('p')`
  font-size: 16px;
  line-height: 34px;
`

const Title = styled('h4')`
  font-size: 36px;
  margin: 70px 0 17px;
  line-height: 50px;
  font-weight: 600;
  color: #ac8a3b;
  ${theme.breakpoints.down('md')} {
    margin: 20px 0 17px;
  }
`

const Ape = styled(Box)`
  /* background: #00182a; */
  padding: 0 160px 80px !important;
  @media (max-width: 1280px) {
    padding: 0 60px 80px !important;
  }
  h4 {
    margin-top: -30px;
  }
  > div {
    display: flex;
    img {
      height: 370px;
      margin-top: 20px;
      /* width: 100%; */
      border: 2px solid;
      border-image: linear-gradient(180deg, rgba(154, 117, 49, 0.12), rgba(192, 154, 32, 0.55)) 2 2;
    }
    > div:first-of-type {
      flex: 1;
    }
    > div:last-of-type {
      width: 412px;
      margin-left: 50px;
    }
    p {
      font-size: 32px;
      font-weight: 600;
      color: #ac8a3b;
      line-height: 50px;
    }
    span {
      color: #ac8a3b;
      font-size: 30px;
      display: block;
      line-height: 46px;
      margin-top: 70px;
      margin-bottom: 84px;
    }
    button {
      width: 100%;
      border: 0;
      background: #ddae12;
      font-size: 26px;
      font-weight: bold;
      border-radius: 10px;
      margin-top: 10px;
      height: 70px;
    }
  }
  ${theme.breakpoints.down('md')} {
    padding: 0 20px 80px !important;
    > div {
      display: block;
      img {
        margin: 0;
        width: 100%;
        height: auto;
      }
      > div:last-of-type {
        width: 100%;
        margin-left: 0;
      }
    }
  }
`

export default function Info() {
  const matches = useMediaQuery((theme: any) => theme.breakpoints.down('md'))
  const playerRef = useRef(null)
  const videoJsOptions = {
    // lookup the options in the docs for more options
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    poster: PosterPic,
    sources: [
      {
        src: MyVideo,
        type: 'video/mp4',
      },
    ],
  }

  const handlePlayerReady = (player: any) => {
    playerRef.current = player

    // you can handle player events here
    player.on('waiting', () => {
      console.log('player is waiting')
    })

    player.on('dispose', () => {
      console.log('player will dispose')
    })
  }
  return (
    <Wrapper>
      <TopImg src={TopPic} alt="" />
      <Box textAlign={'center'}>
        <img width={!matches ? '407px' : '80%'} src={Logo} alt="" />
        <Desc>Mysterious Desert City In the Sandbox</Desc>
      </Box>
      <TopText>
        <h3>Limitations live only in our minds.</h3>
        <p>
          <b>SANCTUARY</b> is an extravagant and fascinating city nested on a desert plot in the Sandbox. It consists of
          32 exclusive and exotic Great Z mansions and 4 communal facilities. Here, every crypto believer will be able
          to find where their true passion lies in the metaverse.
        </p>
      </TopText>
      <Box>
        <Video options={videoJsOptions} onReady={handlePlayerReady} />
      </Box>
      <Box mt="20px" mb={'20px'}>
        <img width={!matches ? '407px' : '80%'} src={Logo} alt="" />
        <Box height={'20px'}></Box>
        <Text>
          Sandbox, the virtual gaming world, is gaining overwhelming popularity. In this exciting world stands the
          Sanctuary, a truly mysterious and aspirational community conjointly created by Paraland and BEAM studio.
        </Text>
        <Text>
          Sanctuary consists of 32 unique and luxurious residences with 4 communal facilities, including an office
          building, a commercial complex, a theme park, and a stadium. The office building welcomes all the partners in
          the metaverse and in the real world; the commercial complex is equipped with shops, restaurants, art gallery,
          cinema, etc.; the theme park is the perfect place where community members can interact with each other and
          enjoy carnival games; and the stadium can hold sports competitions, concerts, fashion shows and other exciting
          events. Sanctuary is the place where your dreams meet the reality.
        </Text>
      </Box>
      <GridArticle />
      <Title>The Great Z mansions</Title>
      <Text>
        Our core concept of design is fusion and contrast, which combines culture, style, and even time to present you
        with an extraordinary experience. In the isolated desert, community members can savor the spectacular scenic
        views of sand dunes and succulent plants while enjoying luxury amenities such as the infinity pool, helipad, and
        spacious foyers and living room.
      </Text>
      <Text>
        Sanctuary will provide you the perfect place for exclusive social activities in the Sandbox, without any
        concerns about the quarantine and social distance.
      </Text>
      <Box height={'32px'}></Box>
      <Box>
        <GCarousel content={carouselData} />
      </Box>
      <Nft />
      <Title>Ape your house now！</Title>
      <Ape mt={'40px'}>
        <Box>
          <Box>
            <img src={ConePic} alt="" />
          </Box>
          <Box mt={'10px'}>
            <p>Get your VIP NFT to start your journey here!</p>
            <span>32 Sets</span>
            <Box mt={'10px'}>
              {/* <img src={NPic} alt="" /> */}
              <button>Coming soon</button>
            </Box>
          </Box>
        </Box>
      </Ape>
      <Faq />
    </Wrapper>
  )
}
