import * as React from 'react'
import { styled } from '@mui/material/styles'
import { Divider } from '@mui/material'
import { NavLink } from 'react-router-dom'
import LogoPic from 'assets/images/svg/logo.svg'
// import GithubLogo from 'assets/images/svg/github.svg'
import TwitterLogo from 'assets/images/svg/twitter.svg'
import DiscordLogo from 'assets/images/svg/discord.svg'
import MediumLogo from 'assets/images/svg/medium.svg'
import PrivateLogo from 'assets/images/svg/private.svg'
import CommercialLogo from 'assets/images/svg/commercial-.svg'
import AboutLogo from 'assets/images/svg/about.svg'
import HomeLogo from 'assets/images/svg/home.svg'
import NftLogo from 'assets/images/svg/nft.svg'
// import MobanLogo from 'assets/images/svg/medium.svg'

import { Box, useMediaQuery, SwipeableDrawer } from '@mui/material'
import theme, { colors } from 'theme'
// import Web3Status from '../Web3Status'
import MenuPic from 'assets/images/svg/menu.svg'

const HeaderBox = styled('header')`
  display: flex;
  width: 100%;
  padding: 30px 60px 0;
  position: relative;
  z-index: 99;
  ${theme.breakpoints.down('md')} {
    padding: 30px 20px 0;
  }
  > div {
    display: flex;
    align-items: center;
    width: 100%;
    ${theme.breakpoints.down('md')} {
      /* padding: 0 1rem; */
      justify-content: space-between;
    }
    img {
      cursor: pointer;
    }
    a {
      margin-right: 10px;
      img {
        ${theme.breakpoints.down('md')} {
          /* height: 24px; */
          margin-left: 15px;
        }
      }
    }
    > nav {
      display: flex;
      font-size: 0.875rem;
      line-height: 21px;
      > div {
        padding: 0 33px;
      }
      a {
        padding: 6px 10px;
        color: ${theme.palette.common.white};
        border-radius: 4px;
        background-color: ${colors.navBackground};
        transition: 0.5s;
        &:last-of-type {
          margin-right: 0;
        }
        &:hover {
          background: ${colors.navHoverBackground};
        }
      }
      a.active {
        background-color: ${colors.navActiceBackground};
      }
    }
  }
`
const Right = styled(Box)`
  a {
    margin-right: 20px !important;
    display: flex;
    &:last-of-type {
      margin-right: 0 !important;
    }
  }
`
const MibileNav = styled(Box)`
  padding-top: 30px;
  a {
    color: #fff;
    display: block;
    line-height: 55px;
    font-size: 16px;
    padding-left: 30px;
    display: flex;
    img {
      margin-right: 10px;
    }
  }
`

export default function Header() {
  const matches = useMediaQuery((theme: any) => theme.breakpoints.up('md'))
  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false)
  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    setDrawerOpen(open)
  }

  return (
    <HeaderBox>
      <div>
        <Box flex={1} display={'flex'} alignItems={'center'}>
          {!matches && (
            <img height="24px" style={{ marginTop: '-5px' }} src={MenuPic} alt="logo" onClick={toggleDrawer(true)} />
          )}
          <a href="/">
            <img src={LogoPic} alt="logo" />
          </a>
        </Box>
        {matches && (
          <nav>
            <NavLink to="/">Home</NavLink>
            <NavLink to="/productssale">Private Property</NavLink>
            <NavLink to="/ce">Commercial Estate</NavLink>
            <NavLink to="/nft">NFT Collection</NavLink>
            <NavLink to="/about">About Us</NavLink>
          </nav>
        )}
        {matches && (
          <Right ml={'30px'} display={'flex'} alignItems={'center'}>
            {/* <a href="http://">
              <img src={GithubLogo} alt="" />
            </a> */}
            <a href="https://twitter.com/Paraland_xyz">
              <img src={TwitterLogo} alt="" />
            </a>
            <a href="https://discord.gg/SPSJFnbdmh">
              <img src={DiscordLogo} alt="" />
            </a>
            <a href="https://medium.com/@paraland.xyz">
              <img src={MediumLogo} alt="" />
            </a>
          </Right>
        )}
      </div>
      <SwipeableDrawer
        sx={{
          display: { sm: 'block', md: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '80%', background: '#1f2231' },
        }}
        anchor={'left'}
        open={matches ? false : drawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {/* <Box textAlign={'right'} p={'26px 16px 18px 0'}>
          <img src={CloseIcon} alt="" style={{ cursor: 'pointer' }} onClick={toggleDrawer(false)} />
        </Box> */}
        <MibileNav>
          <NavLink onClick={toggleDrawer(false)} to="/">
            <img width={25} src={HomeLogo} alt="" />
            Home
          </NavLink>
          <NavLink onClick={toggleDrawer(false)} to="/productssale">
            <img width={25} src={PrivateLogo} alt="" />
            Private Property
          </NavLink>
          <NavLink onClick={toggleDrawer(false)} to="/ce">
            <img width={25} src={CommercialLogo} alt="" />
            Commercial Estate
          </NavLink>
          <NavLink onClick={toggleDrawer(false)} to="/nft">
            <img width={25} src={NftLogo} alt="" />
            NFT Collection
          </NavLink>
          <NavLink onClick={toggleDrawer(false)} to="/about">
            <img width={25} src={AboutLogo} alt="" />
            About Us
          </NavLink>
          <Box mb={'20px'}></Box>
          <Divider style={{ margin: '0 30px' }} />
          <Box mb={'20px'}></Box>
          {/* <a href="http://">
            <img src={GithubLogo} alt="" />
            Github
          </a> */}
          <a href="https://twitter.com/Paraland_xyz">
            <img src={TwitterLogo} alt="" />
            Twitter
          </a>
          <a href="https://discord.gg/SPSJFnbdmh">
            <img src={DiscordLogo} alt="" />
            Discord
          </a>
          <a href="https://medium.com/@paraland.xyz">
            <img src={MediumLogo} alt="" />
            Medium
          </a>
        </MibileNav>
      </SwipeableDrawer>
    </HeaderBox>
  )
}
