import { useState } from 'react'
import { Container, Divider, Grid, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import theme from 'theme'

import { NftData } from './nftConfig'
import Img from 'components/Img'

const Wrapper = styled(Container)`
  width: 100%;
  padding-bottom: 30px;
  h1 {
    font-size: 40px;
    font-weight: 600;
    line-height: 40px;
    margin-bottom: 20px;
    ${theme.breakpoints.down('md')} {
      font-size: 35px;
      line-height: 40px;
    }
  }
  > p {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 30px;
  }
`
const Content = styled('div')`
  margin-top: 30px;
  h2 {
    font-size: 24px;
    font-weight: 500;
    line-height: 35px;
  }
`
const Filter = styled('div')`
  margin-top: 30px;
  margin-bottom: 10px;
  button {
    margin-right: 20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    padding: 8px 16px;
    border-radius: 8px;
    background: transparent;
    color: #86879e;
    border: 1px solid #afa4ff;
    cursor: pointer;
    transition: 0.2s;
    &:hover,
    &.active {
      color: #fff;
      background-color: #2f3563;
    }
    ${theme.breakpoints.down('md')} {
      margin-bottom: 10px;
      padding: 8px 10px;
    }
  }
`

const Item = styled('div')`
  background: #12112f;
  border-radius: 30px;
  padding: 30px;
  text-align: center;
  margin-top: 30px;
  h4,
  h5,
  p {
    font-weight: 600;
    line-height: 30px;
    text-align: left;
  }
  h4 {
    font-size: 24px;
    margin-top: 10px;
  }
  h5 {
    font-size: 18px;
    margin: 13px 0 7px;
  }
  p {
    font-size: 18px;
    color: #afa4ff;
  }
  img {
    width: 100%;
    max-height: 260px;
    object-fit: contain;
  }
`

function RenderGrid(props: any) {
  return (
    <Grid item xs={3}>
      <Item>
        <Box height={'260px'}>
          <Img src={props.i.img} />
        </Box>
        <h4>{props.i.name}</h4>
        <h5 className="ce-type">{props.i.amount}</h5>
        <p>Estimated price: {props.i.price}</p>
      </Item>
    </Grid>
  )
}

export default function Nft() {
  const [filterStatus, setFilterStatus] = useState<string>('All')
  return (
    <Wrapper maxWidth="xl">
      <h1>Meet Your Vision for Future Metalife</h1>
      <p>
        Collaborating with well-known studios, designer brands and IPs, we design a collection of state-of-art NFTs to
        weave your dreams into reality.
      </p>
      <Divider />
      <Content>
        <h2>Ape Now!</h2>
        <Filter>
          <button onClick={() => setFilterStatus('All')} className={filterStatus === 'All' ? 'active' : ''}>
            All
          </button>
          <button
            onClick={() => setFilterStatus('Furnitures')}
            className={filterStatus === 'Furnitures' ? 'active' : ''}
          >
            Furnitures
          </button>
          <button onClick={() => setFilterStatus('Avatars')} className={filterStatus === 'Avatars' ? 'active' : ''}>
            Avatars
          </button>
        </Filter>
        <Grid
          container
          alignItems={'baseline'}
          spacing={{ xs: 0, md: 2, sm: 2 }}
          columns={{ xs: 3, sm: 6, md: 9, lg: 12, xl: 15 }}
        >
          {NftData.map((i: any, k: number) => {
            return i.type === filterStatus && <RenderGrid key={`ce${k}`} i={i} />
          })}
          {filterStatus === 'All' &&
            NftData.map((i: any, k: number) => {
              return <RenderGrid key={`ce${k}`} i={i} />
            })}
        </Grid>
      </Content>
    </Wrapper>
  )
}
