import React, { ReactNode } from 'react'
import { Swiper } from 'swiper/react/swiper-react'
import { Pagination, Autoplay } from 'swiper'
import 'swiper/swiper-bundle.css'

const ParalandSwiper = ({ children }: { children: ReactNode }) => {
  return (
    <Swiper
      autoplay={{
        delay: 2000,
        disableOnInteraction: false,
      }}
      spaceBetween={30}
      pagination={{
        clickable: true,
      }}
      modules={[Autoplay, Pagination]}
    >
      {children}
      {/* <div>
        <SwiperSlide>Slide 1divdivdiv</SwiperSlide>
      </div>
      <SwiperSlide>Slide 2</SwiperSlide>
      <SwiperSlide>Slide 3</SwiperSlide>
      <SwiperSlide>Slide 4</SwiperSlide> */}
    </Swiper>
  )
}
export default ParalandSwiper
