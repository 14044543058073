import { Container, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'

import Firstsale from 'components/Firstsale'
import BG from 'assets/images/png/bg.png'
import BGM from 'assets/images/png/bg-m.png'

const Wrapper = styled(Container)`
  width: 100%;
  height: 100%;
`
const BackgroundImg = styled('img')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`

export default function Home() {
  const matches = useMediaQuery((theme: any) => theme.breakpoints.down('md'))
  return (
    <Wrapper>
      {matches ? <BackgroundImg src={BGM} alt="" /> : <BackgroundImg src={BG} alt="" />}
      <Firstsale />
    </Wrapper>
  )
}
