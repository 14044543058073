import { styled } from '@mui/material/styles'

import theme from 'theme'

// import ParalandSwiper from 'components/ParalandSwiper'
// import { SwiperSlide } from 'swiper/react/swiper-react'
// import PPic1 from 'assets/images/png/pp1.png'
import ArrowRightPic from 'assets/images/svg/arrow-right-blue.svg'

const Wrapper = styled('div')`
  background: #000000;
  border-radius: 20px;
  padding: 20px;
  /* max-width: 100%; */
  ${theme.breakpoints.down('md')} {
    margin-bottom: 20px;
  }
`

const Time = styled('p')`
  margin: 0 50px;
  font-size: 14px;
  line-height: 32px;
  background: rgba(0, 0, 0, 0.7);
  border: 1px solid #afa4ff;
  height: 32px;
  border-radius: 16px;
  font-size: 14px;
  text-align: center;
`
const ImgsWrapper = styled('div')`
  width: auto;
  height: 250px;
  position: relative;
  :after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    box-shadow: 0 0 50px 30px #000 inset;
  }
`
const Imgs = styled('img')`
  width: 100%;
  height: 100%;
  box-shadow: 0 0 50px 30px #000 inset;
`

const Title = styled('h3')`
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  margin-top: 10px;
`

const Soure = styled('p')`
  font-size: 14px;
  font-weight: 500;
  color: #808080;
  line-height: 14px;
  margin: 8px 0;
`

const Price = styled('a')`
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #5d5692;
  display: flex;
  img {
    margin-left: 8px;
    opacity: 0.5;
  }
  &.active {
    color: #afa4ff;
    img {
      opacity: 1;
    }
  }
`

export default function Cards({
  content,
}: {
  content: { title: string; name: string; text: string; status: boolean; imgs: string[] }
}) {
  return (
    <Wrapper>
      <Time>{content.title}</Time>
      <ImgsWrapper>
        <Imgs src={content.imgs[0]} alt="" />
        {/* <ParalandSwiper>
          {content.imgs.map((i, k) => {
            return (
              <SwiperSlide key={`on-sale-mate-${k}`}>
                <Imgs src={i} alt="" />
              </SwiperSlide>
            )
          })}
        </ParalandSwiper> */}
        {/* <Imgs src={PPic1} alt="" /> */}
      </ImgsWrapper>
      <Title>{content.name}</Title>
      <Soure>{content.text}</Soure>
      <Price className={content.status ? 'active' : ''}>
        Learn more
        <img width={'16px'} src={ArrowRightPic} alt="" />
      </Price>
    </Wrapper>
  )
}
