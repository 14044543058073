import { createTheme } from '@mui/material/styles'
import { red } from '@mui/material/colors'
import BulletPic from 'assets/images/svg/bullet.svg'
import Cbg from 'assets/images/png/cbg.png'

interface COLOR {
  navActiceBackground: string
  navBackground: string
  navHoverBackground: string
  firstSaleBackgound: string
  barBackground: string
  barSecondBackground: string
  barThirdBackground: string
  barFourthBackground: string
  yellow: string
  fontColor: string
  fontColor2: string
  fontColor3: string
}

export const colors: COLOR = {
  navActiceBackground: '#2F3563',
  navHoverBackground: 'rgba(47, 53, 99, 0.5)',
  navBackground: 'rgba(47, 53, 99, 0.3)',
  firstSaleBackgound: 'rgba(216, 213, 255, 1)',
  barBackground: '#4221DE',
  barSecondBackground: '#F7F7F7',
  barThirdBackground: '#1B4CE4',
  barFourthBackground: '#164CF5',
  yellow: '#FCB707',
  fontColor: '#04092E',
  fontColor2: '#585A82',
  fontColor3: '#fff',
}

// A custom theme for this app
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1280,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: '#2F3563',
      // dark: '#111111',
      contrastText: '#03011B',
    },
    secondary: {
      main: 'rgba(102, 102, 102, 0.08)',
    },
    warning: {
      main: '#FC9F49',
    },
    error: {
      main: red.A400,
    },
    background: {
      paper: '#fff',
      default: '#03011B',
    },
    grey: {
      50: 'rgba(255, 255, 255, 0.8)',
      100: 'rgba(255, 255, 255, 0.5)',
      200: 'rgba(0, 0, 0, 0.2)',
    },
  },
  shape: {
    borderRadius: 0,
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
    h1: {
      fontSize: '2.25rem',
      lineHeight: '47px',
      fontWeight: 600,
      '@media (max-width: 899.5px)': {
        fontSize: '1.333333rem',
        lineHeight: '20px',
      },
    },
    h4: {
      fontSize: '1.75rem',
      lineHeight: 2,
      fontWeight: 600,
    },
    h5: {
      fontSize: '1.25rem',
      lineHeight: 2,
      fontWeight: 600,
    },
    subtitle1: {
      fontWeight: '600',
      lineHeight: 2,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: '21px',
      color: '#fff',
    },
    body2: {
      fontSize: '1.25rem',
      color: '#666666',
      lineHeight: '26px',
      whiteSpace: 'pre-wrap',
    },
    subtitle2: {
      fontWeight: '600',
      lineHeight: 1.5,
      color: 'rgba(255, 255, 255, 0.5)',
      fontSize: '1rem',
    },
    button: {
      fontSize: '1rem',
      fontWeight: 600,
    },
    overline: {
      fontSize: '1rem',
      color: 'rgba(255, 255, 255, 0.8)',
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: `
          margin-top: 40px;
          padding-left: 60px;
          padding-right: 60px;
          @media (min-width: 600px){
            padding-left: 60px;
            padding-right: 60px;
            box-sizing: border-box;
          }
          @media (max-width: 899.95px){
            padding-left: 20px;
            padding-right: 20px;
          }
          @media (min-width: 1536px){
            margin-top: 100px;
          }
        `,
      },
    },
    MuiButton: {
      styleOverrides: {
        sizeLarge: `
          font-size: 1.25rem;
        `,
        root: `
          color: #fff;
          padding: 9px 16px;
          border-radius: 4px;
          box-shadow: none;
          text-transform: none;
          font-size: 0.875rem;
          border: 1px solid #AFA4FF;
          &:hover {
            opacity: 0.8;
            box-shadow: none;
          }
          &.Mui-disabled {
            color: rgba(255, 255, 255, 0.5);
            background-color: rgba(42, 100, 251, 1);
          }
        `,
        containedPrimary: `
          &.Mui-disabled {
            background-color: #7498f5;
            color: #fff;
          }
        `,
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: `
          min-width: 35px;
        `,
      },
    },
    MuiTab: {
      styleOverrides: {
        root: `
          flex: 1;
          color: rgba(255, 255, 255, 0.8);
          background-color: rgba(16, 14, 38, 1);
        `,
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: `
          background-color: transparent;
        `,
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: `
          margin-bottom: 0px;
          border-radius: 0;
        `,
        thumb: `
          border-radius: 0px;
          width: 8px;
        `,
        track: `
          height: 12px;
        `,
        mark: `
          display: none;
        `,
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: `
          border-color: #484A8C;
        `,
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: `
          max-width: 640px;
          border-radius: 12px;
        `,
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: `
          color: #fff;
        `,
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: `
          background: ${colors.barSecondBackground};
          color: #111;
          padding: 20px 40px;
          font-size: 1.625rem;
          line-height: 2.5rem;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: space-between;
        `,
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        * {
          box-sizing: border-box;
          margin: 0;
          padding: 0;
        }
        html {
          @media (max-width: 899.5px){
            font-size: 12px;
          }
        }
        body {
          background: url(${Cbg}) no-repeat;
          background-size: cover;
          font-family: Poppins, sans-serif;
          font-style: normal;
          color: #fff;
          overflow-x: hidden;
        }
        a {
          text-decoration: none;
          color: #111;
        }
        .carousel .thumb, .carousel .thumb:focus {
          border: 0;
        }
        .carousel .thumb {
          opacity: 0.6;
        }
        .carousel .thumb.selected, .carousel .thumb:hover {
          border: 0;
          opacity: 1;
          // height: 28.77px;
        }
        // .carousel .thumbs-wrapper {
        //   position: absolute;
        //   bottom: 0;
        // }
        .MuiTypography-gutterBottom {
          margin-bottom: 1.375rem !important;
        }
        .MuiTab-root.Mui-selected {
          background: transparent;
        }
        .input-ph-r {
          input {
            padding-right: 8px;
            text-align: right;
            font-weight: 600;
            &::placeholder {
              text-align: right;
              color: rgba(3, 1, 27, 0.2)
            },
          },
        },
        .PrivateSwipeArea-root {
          width: 0 !important;
          padding: 0 !important;
        }
        .swiper-pagination-bullet {
          background: rgba(255, 255, 255, 0.6);
          width: 10px;
          height: 10px;
          margin: 0 10px !important;
        }
        .swiper-pagination-bullet-active {
          background: url(${BulletPic}) no-repeat;
          background-size: cover;
          width: 30px;
          height: 30px;
        }
        .video-js.vjs-fluid, .video-js.vjs-16-9, .video-js.vjs-4-3, .video-js.vjs-9-16, .video-js.vjs-1-1 {
          border: 3px solid #00182a;
        }
        .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding-right: 30px;
          padding-bottom: 30px;
          right: 30px;
          @media (max-width: 899.95px){
            padding-right: 10px;
            padding-bottom: 10px;
            right: 10px;
          }
          width: fit-content;
          left: auto;
        }
        .m-grid {
          @media (max-width: 899.95px){
            display: block !important;
          }
        }
        .Mui-expanded {
          // margin: 0 !important;
        }
        .MuiPaper-root {
          backgrund-color: transparent;
        }
        .MuiAccordion-root {
          backgrund-color: transparent !important;
        }
      `,
    },
  },
})
// console.log(theme)
export default theme
