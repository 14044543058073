import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import theme, { colors } from 'theme'

import HousePic from 'assets/images/png/house.png'
import ArrowRight from 'assets/images/svg/arrow_right.svg'
import Logo from 'assets/images/sanctuary/sanctuary_logo_button.png'

const FirstsaleElement = styled('a')`
  width: 380px;
  height: 60px;
  background: #fff;
  border-radius: 6px;
  position: fixed;
  right: 60px;
  bottom: 50px;
  display: flex;
  ${theme.breakpoints.down('md')} {
    width: 80%;
    right: 10%;
  }
  img.home-house {
    width: 150px;
    height: 124px;
    margin-left: -13px;
    margin-right: 40px;
    margin-top: -35px;
    transition: 0.5s;
    ${theme.breakpoints.down('md')} {
      width: 102px;
      height: 85px;
      margin-top: -10px;
    }
  }
  img.home-arrow-right {
    margin-right: 20px;
    transition: 0.5s;
    ${theme.breakpoints.down('md')} {
      margin-right: 10px;
    }
  }
  p {
    margin-left: -40px;
    &:first-of-type {
      font-size: 20px;
      color: ${colors.fontColor};
      margin-top: 10px;
      font-weight: 600;
      ${theme.breakpoints.down('md')} {
        font-size: 18px;
      }
    }
    &:last-of-type {
      font-size: 12px;
      font-weight: 500;
      color: ${colors.fontColor2};
      display: flex;
      align-items: center;
      img {
        width: 45%;
        margin-left: 10px;
        margin-top: -2px;
      }
    }
  }
  &:hover {
    cursor: pointer;
    img.home-house {
      transform: scale(1.2);
      margin-top: -35px;
      ${theme.breakpoints.down('md')} {
        margin-top: -10px;
      }
    }
    img.home-arrow-right {
      margin-right: 15px;
      ${theme.breakpoints.down('md')} {
        margin-right: 5px;
      }
    }
  }
`

export default function Firstsale() {
  return (
    <FirstsaleElement href="/info/sanctuary">
      <img className="home-house" src={HousePic} alt="" />
      <Box flex={1}>
        <p>
          First Sale is <span>Live</span>
        </p>
        <p>
          Welcome to <img src={Logo} alt={''} />
        </p>
      </Box>
      <img className="home-arrow-right" width={'18px'} src={ArrowRight} alt="" />
    </FirstsaleElement>
  )
}
