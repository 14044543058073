import React from 'react'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import theme from 'theme'

import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
// import UpcompingPic from 'assets/images/sanctuary/upcomping.gif'
// import Ng1 from 'assets/images/sanctuary/ng/1.png'
// import Ng2 from 'assets/images/sanctuary/ng/2.png'
// import Ng3 from 'assets/images/sanctuary/ng/3.png'
// import Ng4 from 'assets/images/sanctuary/ng/4.png'
// import Ng5 from 'assets/images/sanctuary/ng/5.png'
// import Ng6 from 'assets/images/sanctuary/ng/6.png'
// import Ng7 from 'assets/images/sanctuary/ng/7.png'
// import Ng8 from 'assets/images/sanctuary/ng/8.png'
// import SpotPic from 'assets/images/sanctuary/1*1.png'
// import HsPic from 'assets/images/sanctuary/hs.png'
import QuestionsPic from 'assets/images/svg/Questions.svg'

const NFTWrapper = styled(Box)`
  margin-top: 70px;
  background: linear-gradient(180deg, #00030c 0%, #00172b 29%, #00030c 100%);
  h6 {
    color: #ac8a3b;
    font-size: 36px;
    font-weight: 600;
    line-height: 55px;
    padding-bottom: 30px;
    border-bottom: 1px dashed rgba(172, 138, 59, 0.58);
    margin-bottom: 40px;
  }
  div {
    display: flex;
    align-items: center;
  }
  > img {
    height: 24px;
  }
  > div > span {
    position: relative;
    font-size: 30px;
    display: inline-block;
    width: 60px;
    height: 60px;
    min-width: 60px;
    line-height: 60px;
    border-radius: 5px;
    font-weight: 600;
    color: #c1a448;
    text-align: center;
    border: 1px solid;
    &::before {
      position: absolute;
      width: 100%;
      height: 100%;
      content: '';
      display: block;
      border: 2px solid;
      border-image-slice: 10%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-image: linear-gradient(360deg, rgba(154, 117, 49, 1), rgba(194, 165, 72, 1)) 2 2;
    }
    ${theme.breakpoints.down('md')} {
      width: 60px;
      height: 60px;
      min-width: 60px;
      line-height: 60px;
      font-size: 30px;
    }
  }
  p {
    font-weight: 500;
    font-size: 24px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    line-height: 40px;
    img {
      margin-left: 20px;
    }
    ${theme.breakpoints.down('md')} {
      margin-left: 20px;
      font-size: 24px;
      line-height: 30px;
    }
  }
`
// const NFTItem = styled(Box)`
//   display: flex;
//   margin-top: 26px;
//   img {
//     width: 111px;
//     height: 111px;
//     /* border: 2px solid; */
//     background: linear-gradient(180deg, rgb(0 23 42), rgb(0 15 29));
//     /* border-image: linear-gradient(180deg, rgba(154, 117, 49, 1), rgba(194, 165, 72, 1)) 2 2; */
//     margin-right: 27px;
//     border-radius: 10px;
//     &:first-of-type {
//       margin-left: 85px;
//     }
//     &:last-of-type {
//       margin-right: 0;
//     }
//   }
//   ${theme.breakpoints.down('md')} {
//     flex-wrap: wrap;
//     img {
//       margin-right: 10px;
//       width: calc(25% - 10px);
//       height: calc(25% - 10px);
//       margin-bottom: 10px;
//       &:nth-child(4n) {
//         margin-right: 0;
//       }
//       &:first-of-type {
//         margin-left: 0;
//       }
//     }
//   }
// `
const NFTItemLine = styled(Box)`
  width: 1px;
  height: 31px;
  border-right: 1px solid #c1a448;
  padding-left: 30px;
  ${theme.breakpoints.down('md')} {
    padding-left: 30px;
  }
`

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip placement="right" {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#000712',
  },
}))

export default function Nft() {
  return (
    <NFTWrapper>
      <h6>Each Great Z mansion comes with</h6>
      <Box>
        <span>01</span>
        <p>
          <span>ParaLand VIP Membership NFT</span>
          <HtmlTooltip
            title={
              <React.Fragment>
                <p>
                  It will act as a redeemable ticket, a proof that you are the property owner, and guarantees your
                  exclusive access to the Paraland Club ( online and offline ), whitelist, discounts, and future NFT
                  airdrops.
                </p>
              </React.Fragment>
            }
          >
            <img width={'37px'} src={QuestionsPic} alt="" />
          </HtmlTooltip>
        </p>
      </Box>
      <NFTItemLine />
      <Box>
        <span>02</span>
        <p>
          1*1 Sandbox land
          <HtmlTooltip
            title={
              <React.Fragment>
                <p>
                  Sanctuary is located at (-114, 42) with a size of 6*6 (36 parcels) on the Sandbox metaverse. Each
                  Great Z Mansions consists of a 1*1 Sandbox land that can be traded in the future.
                </p>
              </React.Fragment>
            }
          >
            <img width={'37px'} src={QuestionsPic} alt="" />
          </HtmlTooltip>
        </p>
      </Box>
      <NFTItemLine />
      <Box>
        <span>03</span>
        <p>
          Upcoming airdrops
          <HtmlTooltip
            title={
              <React.Fragment>
                <p>
                  After the Great Z Mansions is deployed to the Sandbox land, you will receive extra NFT gifts on your
                  possession date.
                </p>
              </React.Fragment>
            }
          >
            <img width={'37px'} src={QuestionsPic} alt="" />
          </HtmlTooltip>
        </p>
      </Box>
      <NFTItemLine />
      <Box>
        <span>04</span>
        <p>More NFTs asset gifts</p>
      </Box>
      {/* <NFTItem>
        <img src={Ng1} alt="" />
        <img src={Ng2} alt="" />
        <img src={Ng3} alt="" />
        <img src={Ng4} alt="" />
        <img src={Ng5} alt="" />
        <img src={Ng6} alt="" />
        <img src={Ng7} alt="" />
        <img src={Ng8} alt="" />
      </NFTItem> */}
    </NFTWrapper>
  )
}
