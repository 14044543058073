import { Container, Box, Grid, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'
import { SwiperSlide } from 'swiper/react/swiper-react'
import theme, { colors } from 'theme'

import ParalandSwiper from 'components/ParalandSwiper'
import Cards from './Cards'

import NewPic from 'assets/images/png/new.png'
import Pic1 from 'assets/images/meta/withlogo/withlogo.008.png'
import Pic2 from 'assets/images/meta/withlogo/withlogo.001.png'
import Pic3 from 'assets/images/meta/withlogo/withlogo.006.jpeg'
import PicM1 from 'assets/images/meta/m1.png'
import PicM2 from 'assets/images/meta/m2.png'
import PicM3 from 'assets/images/meta/m3.png'
import PicM4 from 'assets/images/meta/m4.png'
import PicM5 from 'assets/images/meta/m5.png'
import PicM6 from 'assets/images/meta/m6.png'
import ArrowRightPic from 'assets/images/svg/arrow-right-blue.svg'

const Wrapper = styled(Container)`
  display: flex;
  padding-bottom: 30px;
  ${theme.breakpoints.down('md')} {
    display: block;
  }
`

const Left = styled(Box)`
  width: 560px;
  margin-top: 18px;
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
  @media (max-width: 1280px) {
    width: 470px;
  }
`

const ProductsSaleNewBackground = styled('img')`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  /* width: 115%; */
  height: 100%;
  border-radius: 30px;
`

const ParalandSwiperItemWrapper = styled('a')`
  display: block;
  position: relative;
  padding: 20px 30px 30px;
  width: 560px;
  height: 531px;
  border-radius: 20px;
  overflow: hidden;
  color: #fff;
  @media (max-width: 1280px) {
    width: 470px;
  }
  ${theme.breakpoints.down('md')} {
    width: 100%;
    padding: 10px;
  }
  div:first-of-type {
    text-align: right;
  }
  div:last-of-type {
    position: absolute;
    bottom: 30px;
    h3 {
      font-size: 40px;
      line-height: 50px;
      ${theme.breakpoints.down('md')} {
        font-size: 35px;
        line-height: 40px;
      }
    }
    p {
      font-size: 20px;
      line-height: 28px;
      margin-top: 6px;
      /* color: #9b9b9b; */
    }
    a {
      color: #afa4ff;
      font-size: 20px;
      line-height: 28px;
      margin-top: 11px;
      display: flex;
      img {
        margin-left: 10px;
      }
    }
  }
`
const Right = styled(Box)`
  margin-left: 50px;
  ${theme.breakpoints.down('md')} {
    margin-left: 0;
    margin-top: 20px;
  }
  h1 {
    line-height: 40px;
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 20px;
    ${theme.breakpoints.down('md')} {
      font-size: 35px;
    }
  }
`
const Desc = styled('p')`
  line-height: 24px;
  font-size: 14px;
  color: ${colors.fontColor3};
  margin-bottom: 16px;
`

const new_data = [
  {
    name: 'Sancturay',
    desc: '32 Sets',
    moreUrl: '/info/sanctuary',
    pic: Pic1,
  },
  {
    name: 'Sancturay',
    desc: '32 Sets',
    moreUrl: '/info/sanctuary',
    pic: Pic2,
  },
  {
    name: 'Sancturay',
    desc: '32 Sets',
    moreUrl: '/info/sanctuary',
    pic: Pic3,
  },
]

const c_data = [
  {
    id: 0,
    title: 'Coming soon',
    name: 'La Neige Vivante',
    text: 'Unique',
    status: false,
    imgs: [PicM1, PicM2, PicM3],
  },
  {
    id: 0,
    title: 'Coming soon',
    name: 'Concrete Heart',
    text: 'Unique',
    status: false,
    imgs: [PicM4, PicM5, PicM6],
  },
]

export default function ProductsSale() {
  const matches = useMediaQuery((theme: any) => theme.breakpoints.down('md'))
  return (
    <Wrapper maxWidth="xl">
      {matches && (
        <Right>
          <h1>Properties on Sale</h1>
          <Desc>
            We build luxury private villas in the metaverse. Each villa is exclusively designed with different
            landscapes, architectural styles and outdoor living spaces. You can design, live and enjoy your dreaming
            lifestyle.
          </Desc>
        </Right>
      )}
      <Left>
        <ParalandSwiper>
          {new_data.map((i, k) => {
            return (
              <SwiperSlide key={`new-mate-${k}`}>
                <ParalandSwiperItemWrapper href="/info/sanctuary">
                  <Box>
                    <img width={127} height={44} src={NewPic} alt="" />
                  </Box>
                  <ProductsSaleNewBackground src={i.pic} alt="" />
                  <Box>
                    {/* <h3>{i.name}</h3> */}
                    <p>{i.desc}</p>
                    <a href={i.moreUrl}>
                      Learn more
                      <img src={ArrowRightPic} alt="" />
                    </a>
                  </Box>
                </ParalandSwiperItemWrapper>
              </SwiperSlide>
            )
          })}
        </ParalandSwiper>
      </Left>
      <Right>
        {!matches && (
          <>
            <h1>Properties on Sale</h1>
            <Desc>
              We build luxury private villas in the metaverse. Each villa is exclusively designed with different
              landscapes, architectural styles and outdoor living spaces. You can design, live and enjoy your dreaming
              lifestyle.
            </Desc>
          </>
        )}
        <Grid
          className="m-grid"
          justifyContent={'center'}
          container
          spacing={{ xs: 0, md: 2, sm: 0 }}
          columns={{ sm: 6, md: 6, lg: 12, xl: 12 }}
        >
          {c_data.map((i, k) => {
            return (
              <Grid key={`p-c-${k}`} item xs={6}>
                <Cards content={i} />
              </Grid>
            )
          })}
        </Grid>
      </Right>
    </Wrapper>
  )
}
