import { Container, Grid, Box, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'
import theme from 'theme'

import Img from 'components/Img'

import CehosePic from 'assets/images/png/cehose.png'
import BlockPic1 from 'assets/images/png/block1.png'
import BlockPic2 from 'assets/images/png/block2.png'
import BlockPic3 from 'assets/images/png/block3.png'

const landData = [
  {
    img: BlockPic1,
    imgWidth: '60px',
    name: 'Ethereum Building',
    price: '25 ETH',
    type: '1*1',
  },
  {
    img: BlockPic2,
    imgWidth: '120px',
    name: '"Zero" Museum',
    price: '80 ETH',
    type: '3*3',
  },
  {
    img: BlockPic3,
    imgWidth: '120px',
    name: 'Starlight Theatre',
    price: '90 ETH',
    type: '3*3',
  },
]

const Wrapper = styled(Container)`
  width: 100%;
  display: flex;
  align-content: center;
`
const GridBg = styled('div')`
  background-image: linear-gradient(#222450 1px, transparent 0), linear-gradient(90deg, #222450 1px, transparent 0);
  background-size: 30px 30px;
  width: 572px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  ${theme.breakpoints.up('xl')} {
    width: 752px;
  }
`
const Content = styled('div')`
  margin-left: 100px;
  /* margin-top: 20px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  h1 {
    font-size: 40px;
    font-weight: 600;
    line-height: 40px;
    margin-bottom: 20px;
    ${theme.breakpoints.down('md')} {
      font-size: 35px;
      line-height: 40px;
    }
  }
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
  }
  ${theme.breakpoints.down('md')} {
    margin-top: 0;
    margin-left: 0;
  }
`

const Land = styled(Box)`
  text-align: center;
  margin-top: 20px;
  margin-bottom: 14px;
  height: 202px;
  p {
    font-size: 14px;
    font-weight: 600;
    line-height: 30px;
  }
  p.ce-type {
    color: #9795a9;
  }
`

export default function CommercialEstate() {
  const matches = useMediaQuery((theme: any) => theme.breakpoints.down('md'))
  return (
    <Wrapper maxWidth="xl">
      {!matches && <GridBg />}
      <Box minWidth={!matches ? '500px' : ''}>{!matches && <Img width={'500px'} src={CehosePic} alt="" />}</Box>
      {/* <Img height={260} src={i.img} /> */}
      <Content>
        <h1>Embrace the Virtual Business Here</h1>
        <p>
          ParaLand partners with the finest and brightest architects and designers over the world to offer residents the
          best of Metaverse life. As a pioneer in architecture design, we are able to compose a symphony of cryptography
          and architectural aesthetics.
        </p>
        <br />
        <p>
          Featuring impeccable style, lavish amenities and the finest of detailing, every single one of our commercial
          buildings will become a landmark.
        </p>
        <br />
        <p> We are developing:</p>
        <Grid container spacing={{ xs: 0, md: 2, sm: 0 }} columns={{ xs: 3, sm: 6, md: 12, xl: 16 }}>
          {landData.map((i, k) => {
            return (
              <Grid key={`ce${k}`} item xs={4}>
                <Land>
                  <Box
                    display={'flex'}
                    alignItems={'flex-end'}
                    justifyContent={'space-around'}
                    height={'93px'}
                    mb="14px"
                  >
                    <img width={i.imgWidth} src={i.img} alt="" />
                  </Box>
                  <p>{i.name}</p>
                  <p className="ce-type">{i.type}</p>
                  <p>Estimated price: {i.price}</p>
                </Land>
              </Grid>
            )
          })}
        </Grid>
      </Content>
    </Wrapper>
  )
}
