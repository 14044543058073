import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import theme from 'theme'
import { Provider } from 'react-redux'
import store from 'state'
import Web3ReactManager from 'components/Web3ReactManager'
import Header from 'components/Header'
// import Footer from 'components/Footer'
import Home from 'pages/Home'
import ProductsSale from 'pages/ProductsSale'
import CommercialEstate from 'pages/CommercialEstate'
import Nft from 'pages/Nft'
import About from 'pages/About'
import Info from 'pages/Info'

import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core'
import { NetworkContextName } from './constants/misc'
import getLibrary from 'utils/getLibrary'

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)
if (!!window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false
}

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Web3ReactProvider getLibrary={getLibrary}>
          <Web3ProviderNetwork getLibrary={getLibrary}>
            <Web3ReactManager>
              <Router>
                <Header />
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/productssale" element={<ProductsSale />} />
                  <Route path="/ce" element={<CommercialEstate />} />
                  <Route path="/nft" element={<Nft />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/info/:id" element={<Info />} />
                </Routes>
                {/* <Footer /> */}
              </Router>
            </Web3ReactManager>
          </Web3ProviderNetwork>
        </Web3ReactProvider>
      </Provider>
    </ThemeProvider>
  )
}
