// import Gif1 from 'assets/images/sanctuary/nft/1.2.gif'
// import Gif3 from 'assets/images/sanctuary/nft/2-2.gif'
// import Gif4 from 'assets/images/sanctuary/nft/2.gif'
// import Gif5 from 'assets/images/sanctuary/nft/3.gif'
// import Gif6 from 'assets/images/sanctuary/nft/4.gif'
import Na1 from 'assets/images/nfts/1.gif'
import Na2 from 'assets/images/nfts/2.gif'
import Na3 from 'assets/images/nfts/3.gif'
import Na4 from 'assets/images/nfts/4.gif'
import Na5 from 'assets/images/nfts/5.gif'
import Na6 from 'assets/images/nfts/6.gif'
import N1 from 'assets/images/nfts/coffe-1.gif'
import N2 from 'assets/images/nfts/cactus.gif'
import N3 from 'assets/images/nfts/lamp.gif'
import N4 from 'assets/images/nfts/lounge-chair.gif'
import N5 from 'assets/images/nfts/props.gif'
import N6 from 'assets/images/nfts/ring.gif'
import N7 from 'assets/images/nfts/sofa.gif'
import N8 from 'assets/images/nfts/table.gif'

export const NftData = [
  {
    img: Na3,
    name: 'Anubis #01',
    price: '0.1 ETH',
    amount: '30/30',
    type: 'Avatars',
  },
  {
    img: Na1,
    name: 'Anubis #02',
    price: '0.1 ETH',
    amount: '30/30',
    type: 'Avatars',
  },
  {
    img: Na2,
    name: 'Osiris #01',
    price: '0.1 ETH',
    amount: '30/30',
    type: 'Avatars',
  },
  {
    img: Na4,
    name: 'Merchant #01',
    price: '0.1 ETH',
    amount: '30/30',
    type: 'Avatars',
  },
  {
    img: Na5,
    name: 'Bonfire Band #01',
    price: '0.1 ETH',
    amount: '30/30',
    type: 'Avatars',
  },
  {
    img: Na6,
    name: 'Bonfire Band #02',
    price: '0.1 ETH',
    amount: '30/30',
    type: 'Avatars',
  },
  {
    img: N1,
    name: 'Little Stool',
    price: '0.8 ETH',
    amount: '5/5',
    type: 'Furnitures',
  },
  {
    img: N2,
    name: 'Potted Cactus',
    price: '0.8 ETH',
    amount: '5/5',
    type: 'Furnitures',
  },
  {
    img: N3,
    name: 'Decorative Lamp',
    price: '0.8 ETH',
    amount: '5/5',
    type: 'Furnitures',
  },
  {
    img: N4,
    name: 'Lounge Chair',
    price: '0.8 ETH',
    amount: '5/5',
    type: 'Furnitures',
  },
  {
    img: N5,
    name: 'Potted Plant',
    price: '0.8 ETH',
    amount: '5/5',
    type: 'Furnitures',
  },
  {
    img: N6,
    name: 'Swim Ring',
    price: '0.8 ETH',
    amount: '5/5',
    type: 'Furnitures',
  },
  {
    img: N7,
    name: 'Sofa Set',
    price: '0.8 ETH',
    amount: '5/5',
    type: 'Furnitures',
  },
  {
    img: N8,
    name: 'Cofee Table',
    price: '0.8 ETH',
    amount: '5/5',
    type: 'Furnitures',
  },
]
