import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { styled } from '@mui/material/styles'
// import ReactPlayer from 'react-player/lazy'
import { Carousel } from 'react-responsive-carousel'
import theme from 'theme'

const SliderBox = styled('div')`
  height: 450px;
  overflow: hidden;

  ${theme.breakpoints.down('md')} {
    height: 250px;
  }
  img {
    height: 100%;
    object-fit: contain;
  }
`
export default function GCarousel({ content = [], changeCarousel, clickItem }: any) {
  const customRenderThumb = (children: any[]) => {
    return children.map((_, index) => {
      return <img src={content[index].url} key={`thumb${index}`} alt=""></img>
    })
  }

  return (
    <Carousel
      // width="760px"
      // axis={'vertical'}
      thumbWidth={144}
      onClickItem={(id) => (clickItem ? clickItem(id) : null)}
      showArrows={false}
      renderThumbs={customRenderThumb}
      onChange={(index) => changeCarousel && changeCarousel(index)}
      showThumbs={content && content.length > 0}
      showIndicators={false}
      showStatus={false}
    >
      {content &&
        content.map((el: any, key: number) => {
          return (
            <SliderBox key={`carousel${key}`}>
              {/* {el.type === 1 ? (
                <ReactPlayer width={'760px'} height={'427px'} playing={true} loop={true} url={el.url}></ReactPlayer>
              ) : ( */}
              <img alt="" src={el.url} />
              {/* )} */}
            </SliderBox>
          )
        })}
    </Carousel>
  )
}
